.main-footer {
    position: relative;
    padding: 25px 0;
    background-image: url('../../static/images/footer-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
}
.main-footer .about-footer {
    position: relative;
    padding-bottom: 30px;
    padding-top: 50px;
}
footer {
    background-color: #fafafa;
}
.main-footer .about-footer ul {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.main-footer .about-footer ul li {
    margin-right: 15px;
}

.main-footer .about-footer ul li .social-link {
    display: block;
}

.main-footer .about-footer ul li .social-link img {
    width: 30px;
}

.main-footer .link-footer {
    position: relative;
    margin-bottom: 30px;
}

.main-footer .link-footer h5 {
    color: var(--Primary, #231916);
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.main-footer .link-footer ul {
    margin-bottom: 0;
    margin-top: 23px;
    display: block;
}

.main-footer .link-footer ul li {
    margin-bottom: 9px;
}

.main-footer .link-footer ul li a {
    color: var(--Primary, #23191680);
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.3s ease-in-out;
}

.main-footer .link-footer ul li a:hover {
    color: #EF8A24;
}

.footer-bottom {
    position: relative;
    padding: 5px 0;
    background-color: #231916;
}

.footer-bottom p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
}

.footer-bottom .about-footer {
    position: relative;
    text-align: right;
}

.footer-bottom .about-footer ul {
    display: flex;
    align-items: center;
    justify-content: end;
}

.footer-bottom .about-footer ul li {
    margin-left: 20px;
    opacity: 0.8;
}

@media(max-width: 500px){
    .main-footer {
        background-size: 100%;
        background-position: bottom center;
    }
}
